const ChevronRight = ({ width = "25px", height = "25px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <title />
    <g id="chevron-right">
      <line
        x1="21"
        x2="12"
        y1="16"
        y2="25"
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "2px",
        }}
      />
      <line
        x1="12"
        x2="21"
        y1="7"
        y2="16"
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "2px",
        }}
      />
    </g>
  </svg>
);

export default ChevronRight;
