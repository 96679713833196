import { get, toLower, map, toNumber, isEmpty, groupBy } from "lodash";

import styles from "components/product/labels/ProductLabels.module.scss";

const ProductLabels = ({
  urlTitle,
  status,
  discount,
  new: newattr,
  favourite,
  labels,
  productHooks,
  options = {},
}) => {
  const showGift = !isEmpty(get(productHooks, "hard", null));
  const grouppedLabels = groupBy(
    isEmpty(labels) ? [] : labels,
    (label) => label.group
  );

  return (
    <div className={`${styles.labels}`}>
      {discount ? (
        <div className={`${styles.label} ${styles.discount}`}>
          <div className={styles.title}>Zľava</div>
          <div className={styles.discount}>-{discount}</div>
        </div>
      ) : (
        ""
      )}
      {status ? (
        <div
          className={`${styles.label} ${styles.status}`}
        >
          {status}
        </div>
      ) : (
        ""
      )}
      {favourite ? (
        <div className={`${styles.label} ${styles.favourite}`}>Obľúbené</div>
      ) : (
        ""
      )}
      {newattr ? (
        <div className={`${styles.label} ${styles.new}`}>Novinka</div>
      ) : (
        ""
      )}
      {showGift ? (
        <div className={`${styles.label} ${styles.gift}`}>Darček</div>
      ) : (
        ""
      )}
      {labels
        ? map(grouppedLabels, (labelGroup, labelGroupName) => {
            if (labelGroupName) {
              return (
                <div
                  className={`${styles.label}`}
                  style={{
                    backgroundColor: get(labelGroup, "[0].backgroundColor"),
                    color: get(labelGroup, "[0].textColor"),
                  }}
                  key={`product-card-labels-custom-${urlTitle}-${labelGroupName}`}
                >
                  {labelGroupName}:
                  {map(labelGroup, (label, index) => (
                    <span
                      style={{ marginLeft: "5px" }}
                      key={`product-card-labels-custom-${urlTitle}-${index}-${labelGroupName}-${get(
                        label,
                        "name"
                      )}`}
                    >
                      {get(label, "name")}
                    </span>
                  ))}
                </div>
              );
            } else {
              return map(labelGroup, (label, index) => {
                return (
                  <div
                    className={`${styles.label}`}
                    style={{
                      backgroundColor: get(label, "backgroundColor"),
                      color: get(label, "textColor"),
                    }}
                    key={`product-card-labels-custom-${urlTitle}-${index}-${get(
                      label,
                      "name"
                    )}`}
                  >
                    {get(label, "name")}
                  </div>
                );
              });
            }
          })
        : ""}
    </div>
  );
};

export default ProductLabels;
